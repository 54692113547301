import * as React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import HeaderMarkets from "../components/headers/header-markets";
import PromiseAppEngIt from "../components/markets/promise-app-eng-it";
import Help from "../components/global/help";
import Projects from "../components/global/projects";
import IconData from "../assets/images/markets/data.svg";
import IconAnalitics from "../assets/images/markets/analitics.svg";
import IconInteraction from "../assets/images/markets/interaction.svg";
import IconPlaning from "../assets/images/markets/planing.svg";
import HeaderBcg from "../assets/images/headers/industrials.png"


const Industrials = () => {
  const { t } = useTranslation()
  // Header Content
  const hTitle = `${t('markets.industrials')}`;
  const hShortText = `${t('marketsPages.industrials.shortText')}`;
  const hText = `${t('marketsPages.industrials.text')}`;
  const helpHeadline = `${t('marketsPages.helpHeadline')}`;

  // Promise Component
  const helpContent = [
    {
      title: `${t('marketsPages.industrials.helpContent.firstCard.title')}`,
      textOne: `${t('marketsPages.industrials.helpContent.firstCard.textOne')}`,
      textTwo: `${t('marketsPages.industrials.helpContent.firstCard.textTwo')}`,
      img: <IconData />,
    },
    {
      title: `${t('marketsPages.industrials.helpContent.secondCard.title')}`,
      textOne: `${t('marketsPages.industrials.helpContent.secondCard.textOne')}`,
      textTwo: `${t('marketsPages.industrials.helpContent.secondCard.textTwo')}`,
      textThree: `${t('marketsPages.industrials.helpContent.secondCard.textThree')}`,
      img: <IconAnalitics />,
    },
    {
      title: `${t('marketsPages.industrials.helpContent.thirdCard.title')}`,
      textOne: `${t('marketsPages.industrials.helpContent.thirdCard.textOne')}`,
      textTwo: `${t('marketsPages.industrials.helpContent.thirdCard.textTwo')}`,
      textThree: `${t('marketsPages.industrials.helpContent.thirdCard.textThree')}`,
      img: <IconInteraction />,
    },
    {
      title: `${t('marketsPages.industrials.helpContent.fourthCard.title')}`,
      textOne: `${t('marketsPages.industrials.helpContent.fourthCard.textOne')}`,
      textTwo: `${t('marketsPages.industrials.helpContent.fourthCard.textTwo')}`,
      textThree: `${t('marketsPages.industrials.helpContent.fourthCard.textThree')}`,
      img: <IconPlaning />,
    },
  ];
  return (
    <Layout>
      <Seo title={hTitle} />
      <HeaderMarkets
        hTitle={hTitle}
        hShortText={hShortText}
        hText={hText}
        bkgImage={HeaderBcg}
        hNum={'93'}
      />
      <Help helpContent={helpContent} helpHeadline={helpHeadline} />
      <PromiseAppEngIt promiseContentItems={['app-dev', 'eng-team', "other-services"]} />
      <Projects />
    </Layout>
  );
};

export default Industrials;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`